import React from 'react';
import { connect } from 'react-redux';
import { controlChanged } from '../../actions/creators';
import { getScaleNames, getChordNames, getPitches } from '../../api';
import Dropdown from '../Dropdown/index';
import './NotesSetter.less';
var NotesSetter = function (_a) {
    var currentScale = _a.currentScale, currentChord = _a.currentChord, notesType = _a.notesType, rootNote = _a.rootNote;
    var notesTypeOptions = [{
            name: 'scale',
            label: 'scale'
        }, {
            name: 'chord',
            label: 'chord'
        }];
    var getChordScaleDropdown = function () {
        var notesTypeData = getScaleNames();
        var ddType = 'scale';
        var selectedValue = currentScale;
        if (notesType === 'chord') {
            notesTypeData = getChordNames();
            ddType = 'chord';
            selectedValue = currentChord;
        }
        return React.createElement(Dropdown, { data: notesTypeData, controlType: ddType, onChangeEventHandler: controlChanged, selectedValue: selectedValue });
    };
    return (React.createElement("ul", { className: "notesSetter" },
        React.createElement("li", null,
            React.createElement(Dropdown, { data: getPitches(), controlType: "rootNote", onChangeEventHandler: controlChanged, selectedValue: rootNote })),
        React.createElement("li", null, getChordScaleDropdown()),
        React.createElement("li", null,
            React.createElement(Dropdown, { data: notesTypeOptions, controlType: "notesType", onChangeEventHandler: controlChanged, selectedValue: notesType }))));
};
var mapStateToProps = function (state) { return ({
    scales: state.scales,
    chords: state.chords,
    notesType: state.notesType,
    rootNote: state.rootNote,
    currentScale: state.scale,
    currentChord: state.chord
}); };
export default connect(mapStateToProps)(NotesSetter);
