import React from 'react';
import { Link } from 'react-router-dom';
import NotesSetter from '../NotesSetter/index';
import './Menu.less';
var Menu = function () {
    var currentSelection = location.hash.replace('#/', '');
    var guitarSelected = currentSelection === 'guitar' ? 'selected' : '';
    var pianoSelected = currentSelection === 'piano' ? 'selected' : '';
    var keyboardSelected = currentSelection === 'keyboard' ? 'selected' : '';
    return (React.createElement("div", { className: "menu" },
        React.createElement("p", null, "Generate chord & scale charts to practice"),
        React.createElement("nav", null,
            React.createElement("ul", null,
                React.createElement("li", { className: guitarSelected },
                    React.createElement(Link, { to: "/guitar" }, "Guitar")),
                React.createElement("li", { className: pianoSelected },
                    React.createElement(Link, { to: "/piano" }, "Piano")),
                React.createElement("li", { className: keyboardSelected },
                    React.createElement(Link, { to: "/keyboard" }, "Keyboard")))),
        React.createElement(NotesSetter, null)));
};
export default Menu;
