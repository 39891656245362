import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { rootReducer } from './reducers/root';
import { initApp } from './actions/creators';
import App from './App';
import MidiInput from './components/MidiInput';
// const store = createStore(rootReducer,
// 	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
var store = createStore(rootReducer);
var render = function () {
    ReactDOM.render(React.createElement(HashRouter, null,
        React.createElement(Provider, { store: store },
            React.createElement(React.Fragment, null,
                React.createElement(App, null),
                React.createElement(MidiInput, null)))), document.getElementById('root'));
};
store.subscribe(render);
initApp(store.dispatch);
