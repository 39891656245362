import constants from '../actions/constants';
import { getOctavesOfPianoNotes, getChord, getScale } from '../api';
var initialState = {
    octavesOfPianoNotes: getOctavesOfPianoNotes(),
    scale: 'Major',
    chord: 'M',
    notesType: 'scale',
    rootNote: 'C',
    notes: [],
    fretboardIsVFlipped: false,
    fretboardIsHFlipped: false,
    selectedTuningIdx: 0,
    selectedInstrumentIdx: 0,
    activeMidiNote: '',
};
export var rootReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case constants.LOAD_NOTES:
            var newState_1 = Object.assign({}, state, action.data);
            if (newState_1.notesType === 'chord') {
                newState_1.notes = getChord(newState_1.rootNote + newState_1.chord);
            }
            else {
                newState_1.notes = getScale(newState_1.rootNote, newState_1.scale);
            }
            newState_1.octavesOfPianoNotes.forEach(function (oct) {
                oct.forEach(function (key) {
                    key.highlight = newState_1.notes.indexOf(key.note) > -1;
                    key.rootNote = key.name === newState_1.rootNote;
                });
            });
            return newState_1;
        case constants.VFLIP_FRETBOARD:
            return Object.assign({}, state, {
                fretboardIsVFlipped: !state.fretboardIsVFlipped,
            });
        case constants.HFLIP_FRETBOARD:
            return Object.assign({}, state, {
                fretboardIsHFlipped: !state.fretboardIsHFlipped,
            });
        case constants.CHANGE_TUNING:
            return Object.assign({}, state, action.data);
        case constants.CHANGE_INSTRUMENT:
            return Object.assign({}, state, action.data);
        case constants.MIDI_NOTE_ON:
            // action.data is a note such as C3 or Db5 etc
            return Object.assign({}, state, { activeMidiNote: action.data });
        case constants.MIDI_NOTE_OFF:
            return Object.assign({}, state, { activeMidiNote: '' });
        default:
            return state;
    }
};
