import React from 'react';
import { Route, Link } from 'react-router-dom';
import Loadable from 'react-loadable';
import Menu from './components/Menu/index';
export var LoadablePiano = Loadable({
    loader: function () { return import(/* webpackChunkName: "piano" */ './modules/Piano'); },
    loading: function () { return React.createElement("div", null, "loading ..."); },
});
export var LoadableGuitar = Loadable({
    loader: function () { return import(/* webpackChunkName: "guitar" */ './modules/Guitar'); },
    loading: function () { return React.createElement("div", null, "loading ..."); },
});
export var LoadableComputerKeyboard = Loadable({
    loader: function () { return import(/* webpackChunkName: "computerKeyboard" */ './modules/ComputerKeyboard'); },
    loading: function () { return React.createElement("div", null, "loading ..."); },
});
var App = function () {
    // Simple hack to get the current route
    var mainClass = 'page';
    if (location.hash === '#/') {
        mainClass = 'home';
    }
    mainClass += ' app';
    return (React.createElement("section", { className: mainClass },
        React.createElement(Link, { to: "/" },
            React.createElement("h1", { className: "logo" }, "Johann")),
        React.createElement(Menu, null),
        React.createElement(Route, { path: "/piano", exact: true, component: LoadablePiano }),
        React.createElement(Route, { path: "/guitar", exact: true, component: LoadableGuitar }),
        React.createElement(Route, { path: "/keyboard", exact: true, component: LoadableComputerKeyboard })));
};
export default App;
