import constants from './constants';
import { getTuningsForGuitar, getTuningsForUkulele, getStringInstruments, getTuningsFor7StringsGuitar } from '../api';
var initApp = function (dispatch) { return dispatch({ type: constants.LOAD_NOTES }); };
var controlChanged = function (dispatch, data) {
    dispatch({
        type: constants.LOAD_NOTES,
        data: data,
    });
};
var vFlipFretboard = function (dispatch) {
    dispatch({
        type: constants.VFLIP_FRETBOARD,
    });
};
var hFlipFretboard = function (dispatch) {
    dispatch({
        type: constants.HFLIP_FRETBOARD,
    });
};
var changeTuning = function (tuningMethod) { return function (dispatch, data) {
    // selectedTuningIdx comes in as a string, for eg "Drop C"
    // (hack) convert it to a integer explicitly till DropDown is reimplemented
    var tuning = tuningMethod.find(function (el) { return el.label === data.selectedTuningIdx; });
    dispatch({
        type: constants.CHANGE_TUNING,
        data: { selectedTuningIdx: tuning.tuningIdx },
    });
}; };
var changeInstrument = function (dispatch, data) {
    var instrument = getStringInstruments().find(function (el) { return el.label === data.selectedInstrumentIdx; });
    dispatch({
        type: constants.CHANGE_INSTRUMENT,
        data: {
            selectedTuningIdx: 0,
            selectedInstrumentIdx: instrument.instrumentIdx,
        },
    });
};
var changeGuitarTuning = changeTuning(getTuningsForGuitar);
var changeUkuleleTuning = changeTuning(getTuningsForUkulele);
var change7StringsGuitarTuning = changeTuning(getTuningsFor7StringsGuitar);
var setMidiNoteOn = function (dispatch, data) {
    dispatch({
        type: constants.MIDI_NOTE_ON,
        data: data,
    });
};
var setMidiNoteOff = function (dispatch) {
    dispatch({
        type: constants.MIDI_NOTE_OFF,
    });
};
export { initApp, controlChanged, vFlipFretboard, hFlipFretboard, changeTuning, changeGuitarTuning, changeUkuleleTuning, change7StringsGuitarTuning, changeInstrument, setMidiNoteOn, setMidiNoteOff, };
