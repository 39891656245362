var _this = this;
import React from 'react';
import { connect } from 'react-redux';
/**
 * @param {Array} data A list of (object) items that have the name and value of the option to be displayed
 * @param {Function} onChangeEventHandler A function that will be triggered for the onChange event of the dropdown
 * @param {String} controlType A string that signifies what sort of control this dropdown is (e.g. scale, chord, notesType etc)
 * @param {String} selectedValue The value that will determine which option of this dropdown will be shown as selected
 * @param {Function} dispatch The dispatch function to be passed on to the action creator
 */
var Dropdown = function (_a) {
    var data = _a.data, onChangeEventHandler = _a.onChangeEventHandler, controlType = _a.controlType, selectedValue = _a.selectedValue, dispatch = _a.dispatch;
    var list = data.map(function (item) { return React.createElement("option", { value: item.label, key: item.label }, item.label); });
    var localOnChangeHandler = function (e) {
        var dataObj = {};
        dataObj[e.target.dataset.controlType] = e.target.value;
        onChangeEventHandler(dispatch, dataObj);
    };
    return (React.createElement("select", { onChange: localOnChangeHandler.bind(_this), "data-control-type": controlType, value: selectedValue }, list));
};
// Use the default mapDispatchToProps by not passing it which is basically the same as passing dispatch => ({ dispatch })
export default connect()(Dropdown);
